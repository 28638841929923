@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');

body{
    font-family: 'Open Sans', sans-serif;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
 }
 @layer utilities {
    @variants responsive {
        /* Hide scrollbar for Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge and Firefox */
        .no-scrollbar {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
        @media only screen and (max-width: 640px) {
            .litepie-datepicker {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                /* background-color: #cb2027; */
            }
            .leading-relaxed  {
                color: #cb2027
            }
            .away-cancel-picker {
                background-color: #cb2027;
                color: #ffffff;
            }
          }
          .shadowBox{
            -webkit-box-shadow: 0 0 10px 2px #e6e6e6;
            -moz-box-shadow: 0 0 10px 2px #e6e6e6;
            box-shadow: 0 0 10px 2px #e6e6e6;
          }

        /* .litepie-datepicker {
            background-color: #cb2027;
        }
        .litepie-datepicker span{
            background-color: #cb2027;
        }
        .litepie-datepicker-date {
            color: #ffffff
        }
        .text-litepie-secondary-500{

        } */
        /* .border-litepie-secondary-700 {
            border-color: #ffffff
        } */
        button:disabled,
        button[disabled]  {
        /* border: 1px solid #999999;
        background-color: #cccccc; */
        /* color: #D3D3D3; */
        cursor: not-allowed;
        }
        input[type="checkbox"] {
            /* height: 24px;
            width: 24px; */
            color: #cb2027;
        }
        .checkout-line-padding{
            padding-bottom: 1%;
        }
        .checkout-line-padding-s2{
            padding-bottom: 1.5%;
        }
    }

  }
  .cursor-pointer {
        cursor: pointer;
    }
  #tabs {
    cursor: pointer;
}
.tab-list-move {
    transition: transform 1s;
}
/* Disables Blue Outline in Safari */
*:focus {
    outline: none;
}
button:focus {
    outline: none;
}
.loader {
    border-top-color: #cb2027;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
  }
.w102{
    width: 102%;
}
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
